<template>
  <div>
    <div v-if="data">
      <div v-html="data.html"></div>
    </div>
    <div v-else>
      <div v-html="html"></div>
    </div>
  </div>
</template>

<script>
import Base from './Base.vue'
export default {
  extends: Base,
  props: {
    html: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
</style>